import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style1">
        <div className="inner">
            <header className="major">
                <h1>都市構造可視化計画ウェブサイト</h1>
            </header>
            <div className="content">
                <p>都市構造可視化計画ウェブサイトは、日本都市計画学会、福岡県、建築研究所が連携して運営しているウェブサイトです。<br/>
                当機構は、技術的な支援および運営協力を行っています。</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
