import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerKashikakeikaku from '../components/BannerKashikakeikaku'

const Kashikakeikaku = (props) => (
    <Layout>
        <Helmet>
            <title>都市構造可視化計画ウェブサイト</title>
            <meta name="description" content="都市構造可視化計画ウェブサイトの概要" />
        </Helmet>

        <BannerKashikakeikaku />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>都市構造可視化計画ウェブサイトとは</h2>
                    </header>
                    <p>　都市構造可視化計画ウェブサイトは、日本都市計画学会、福岡県、建築研究所が連携して運営しているウェブサイトです。<br/>
                    　当機構は、技術的な支援および運営協力を行っています。</p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <a href="https://mieruka.city/" className="image" target="_blank" rel="noopener noreferrer">
                        <img src="/mierukacity.png" alt="" />
                    </a>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>都市構造可視化計画ウェブサイト</h3>
                            </header>
                            <p>都市構造可視化計画ウェブサイトです。<br/>
                            全国のデータが掲載されています。<br/>2015年のオープン以来、可視化技術の向上、データの充実を進めています。</p>
                            <ul className="actions">
                                <li><a href="https://mieruka.city/movies/structure" className="button" target="_blank" rel="noopener noreferrer">都市構造可視化計画の使い方</a></li>
                            </ul>
                            <ul className="actions">
                                <li><a href="https://mieruka.city/" className="button" target="_blank" rel="noopener noreferrer">都市構造可視化計画ウェブサイト</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <a href="https://minna.mieruka.city/" className="image" target="_blank" rel="noopener noreferrer">
                        <img src="/ideasyu.png" alt="" />
                    </a>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>都市構造可視化計画 活用アイデア集</h3>
                            </header>
                            <p>都市構造可視化に関する事例やアイデアを収集して提供しています。<br/>地域独自のデータの活用などがあります。
                            </p>
                            <ul className="actions">
                                <li><a href="https://minna.mieruka.city/" className="button" target="_blank" rel="noopener noreferrer">都市構造可視化計画 活用アイデア集</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="image">
                        <img src="/top_l5.png" alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>「都市構造可視化」を知る</h3>
                            </header>
                            <p>
                            ・動画を見る<br/>
                            　- <a href="https://youtu.be/G9GpggxoT60" target="_blank" rel="noopener noreferrer">ワークショップでの活用風景 (3min)</a><br/>
                            　- <a href="https://mieruka.city/members/view" target="_blank" rel="noopener noreferrer">有識者による可視化の紹介動画 (10min×10)</a><br/>
                            　- <a href="https://youtu.be/wWVBsedDmG4" target="_blank" rel="noopener noreferrer">まちづくりのために都市を見える化しよう (50min)</a><br/>
                            <br/>
                            ・論文を見る<br/>
                            {/* 　- <a href="https://www.jstage.jst.go.jp/article/journalcpij/45.3/0/45.3_169/_pdf" target="_blank" rel="noopener noreferrer">関東地方における都市構造の可視化推進に関する研究</a><br/>
                            　- <a href="https://www.jstage.jst.go.jp/article/journalcpij/46/3/46_3_445/_pdf">メッシュ単位の将来人口推計手法を用いた都市構造の可視化に関する研究</a><br/> */}
                            　- <a href="https://www.cpij.or.jp/com/ac/reports/20_146.pdf" target="_blank" rel="noopener noreferrer">高校地理教育における都市構造可視化の活用に関する研究</a><br/>
                            <br/>
                            {/* ・使い方を見る＆使ってみる<br/>
                            　- <a href="https://mieruka.city/movies/structure" target="_blank" rel="noopener noreferrer">都市構造可視化計画の使い方</a><br/>
                            　- <a href="https://mieruka.city/" target="_blank" rel="noopener noreferrer">都市構造可視化計画ウェブサイト</a><br/>
                            <br/> */}
                            ・（参考）都市計画での活用事例を見る<br/>
                            　- <a href="https://minna.mieruka.city/" target="_blank" rel="noopener noreferrer">都市構造可視化計画 活用アイデア集</a><br/>
                            　- <a href="https://meet2020.kashika.or.jp/" target="_blank" rel="noopener noreferrer">i-都市交流会議2020イベントサイト (英語)</a><br/>
                            　- <a href="https://sns.kashika.or.jp/public/i_toshi2020_1/" target="_blank" rel="noopener noreferrer">i-都市交流会議2020活動報告 (128自治体の発表事例等)</a><br/>
                            </p>

                        </div>
                    </div>
                </section>
                <section>
                    <a href="https://kashika-new-dev.kashika.net/" className="image" target="_blank" rel="noopener noreferrer">
                        <img src="/newmieruka.png" alt="" />
                    </a>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>新可視化サイト（開発中暫定版）</h3>
                            </header>
                            <p>都市構造可視化計画ウェブサイトの新バージョンを開発中です。（変更履歴なく大幅に変更されます。URLも変わることがあります。）</p>
                            <ul className="actions">
                                <li><a href="https://kashika-new-dev.kashika.net/" className="button" target="_blank" rel="noopener noreferrer">新可視化サイト（暫定版）</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Kashikakeikaku